<template>
  <b-tabs content-class="mt-1">
    <b-tab title="Мапа АЗС">
      <leaflet-basic :title="'Мапа АЗС'" :locations="fuelStations.locations" :companies="fuelStations.companies"/>
    </b-tab>
  </b-tabs>
</template>

<script>
import {BTabs, BTab} from 'bootstrap-vue';
import LeafletBasic from './LeafletBasic.vue';
import Maps from './maps';

export default {
  components: {
    BTabs,
    BTab,
    LeafletBasic,
  },
  data() {
    return {
      deposits: [],
      fuelStations: [],
    };
  },
  methods: {
  },
  async created() {
    this.fuelStations = await Maps.fuelStations();
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/map-leaflet.scss';
</style>
