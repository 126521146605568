import axiosIns from '@/libs/axios';

class Maps {
  deposits() {
    return axiosIns
      .get('/business/maps/deposits')
      .then(response => {
        return response.data;
      }).catch(() => {
        return false;
      });
  }

  fuelStations() {
    return axiosIns.get('/business/maps/fuels')
      .then(response => {
        return response.data;
      }).catch(() => {
        return false;
      });
  }
}

export default new Maps();
